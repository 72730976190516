import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from '../functions/useMediaQuery'
import iPhone11BG from '../../images/iPhone11BG.jpg'
import iPadProBG from '../../images/iPadProBG.jpg'
import webBG from '../../images/WebBG.jpg'
import mobileSmallBG from '../../images/mobileSmallBG.jpg'
import Logo from '../../images/NestBoxLiveLogo.png'
import { Container, Button } from '@material-ui/core'
import firebase from '../../firebase'
import { StyledFirebaseAuth } from 'react-firebaseui'
import { useAuth } from '../../context/AuthContext'
import HomePageModal from './HomePageModal'
import appleAppStore from '../../images/appleAppStore.png'
import googlePlayStore from '../../images/google-play-badge.png'

export default function SignUpHome() {
    const [backgroundImage, setBackgroundImage] = useState(null)
    const [innerWidth, innerHeight] = useMediaQuery()
    const [signUp, setSignUp] = useState(false)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()


    const { signup } = useAuth()

    const history = useHistory()

    const selectBackgroundImage = (innerWidth, innerHeight) => {
        console.log(innerWidth)
        if (innerWidth <= 360 & innerHeight <= 560) {
            setBackgroundImage(mobileSmallBG)
        } else if (innerWidth <= 414 & innerHeight <= 715) {
            setBackgroundImage(iPhone11BG)
            console.log(backgroundImage)
        } else if (innerWidth <= 768 & innerHeight <= 954) {
            setBackgroundImage(iPadProBG)
        } else {
            setBackgroundImage(webBG)
        }
    }

    const signInOptionsWithScope = [
        {
            provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            scopes: [
                'public_profile',
                'email',
                'publish_video'
            ]
        },
        // {
        //     provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID
        // },
        {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
        }
    ]

    

    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/',
        // We will display Google and Facebook as auth providers.
        signInOptions: signInOptionsWithScope,
        // Callback functions.
        callbacks: {
            signInSuccessWithAuthResult: async (authResult) => {
                localStorage.setItem('facebookAccessToken', authResult.credential.accessToken)
                // authResult.credential.accessToken
                return true
            }
        }
    };

    

    const handleSignUpClick = (e) => {
        setSignUp(true)
    }

    async function handleSubmit(e) {
        e.preventDefault()
        
        // Validation checks
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match')
        }
        try {
            setError('')
            setLoading(true)
            await signup(firstNameRef.current.value, lastNameRef.current.value, emailRef.current.value, passwordRef.current.value)
            history.push('/')
        } catch (e) {
            setError('Failed to create an account')
        }

        setLoading(false)
    }

    function handleCloseModal() {
        setModalOpen(false)
    }

    useEffect(() =>{
        selectBackgroundImage(innerWidth, innerHeight)
    }, [innerWidth, innerHeight])

    return (
        <React.Fragment>
        <div style={{ backgroundColor: `black`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: '100vw', height: '100vh' }} >
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', position: 'relative', top: '50%', transform: 'translateY(-50%)'}}>
                <Container maxWidth='xs' >
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', position: 'relative', paddingBottom: '10px'}}>
                        <img src={Logo} alt='Nest Box Live' width='80%'/>
                    </div>
                    <div style={{display: 'flex',  
                        fontFamily: 'sans-serif',
                        justifyContent:'center', 
                        alignItems:'center', 
                        position: 'relative', 
                        paddingBottom: '10px', 
                        color: 'white', 
                        fontSize: 20,
                        textAlign: 'center',
                    }}>
                        This web app has now been depreciated.
                        Please download the native App by clicking one of the links below.
                    </div>
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', position: 'relative', color: 'white'}}>
                        <a href='https://apps.apple.com/us/app/nest-box-live/id1604051432'>
                            <img src={appleAppStore} alt='Apple App Store' width={200} />
                        </a>
                    </div>
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', position: 'relative', color: 'white'}}>
                        <a href='https://play.google.com/store/apps/details?id=com.nestboxappreactnative'>
                            <img src={googlePlayStore} alt='Google Play Store' width={250}/>
                        </a>
                    </div>
                    <div style={{display: 'flex',  
                        justifyContent:'center', 
                        fontFamily: 'sans-serif',
                        alignItems:'center', 
                        position: 'relative', 
                        paddingBottom: '10px', 
                        color: 'white', 
                        fontSize: 15,
                        textAlign: 'center',
                        }}>
                        2022 Nest Box Live LTD
                    </div>
                    {/* <React.Fragment>
                        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', position: 'relative', color: 'white'}}>

                        Choose a sign in method below:
                        </div>
                        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', position: 'relative'}}>
                        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                        </div>
                        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', position: 'relative'}}>
                        <Button variant="text" onClick={(e) => history.push('/setup')} style={{color: 'white'}}>Setup instructions</Button>
                        </div>
                    </React.Fragment> */}
                </Container>
            </div>
        </div>
        <HomePageModal open={modalOpen} handleClose={handleCloseModal} />
        </React.Fragment>
    )
}