import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyAlvRQWucT9tGxIEw2JVLBGeeAcJiWoO8k",
    authDomain: "nestboxlivedata.firebaseapp.com",
    databaseURL: "https://nestboxlivedata-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "nestboxlivedata",
    storageBucket: "nestboxlivedata.appspot.com",
    messagingSenderId: "263699871647",
    appId: "1:263699871647:web:f3af141651de4d1bf76faa",
    measurementId: "G-WR7PE86HTE"
  };

firebase.initializeApp(firebaseConfig);

export default firebase;