import React, { useState, useEffect } from 'react'
import { Alert } from '@material-ui/lab';

const alertConnected = <Alert severity='success' style={{width: '100%'}}>Connected to the Nest Box Live network</Alert>
const alertDisconnected = <Alert severity='error' style={{width: '100%'}}>Not conneected to the Nest Box Live network</Alert>

export default function NetworkConnection(props) {
    const [networkConnectionState, setNetworkConnectionState] = useState(null);

    function getNestBoxLiveStatus() {
        let now = new Date().getTime() / 1000;
        console.log(now)
        console.log(props.serverTimestamp)
        const lastUpdate = Math.round(now - props.serverTimestamp);
        console.log(lastUpdate)
        console.log(props.timeoutDelta)

        if (lastUpdate < props.timeoutDelta) {
            setNetworkConnectionState(true);
        } else {
            setNetworkConnectionState(false);
        };
    };
    
    useEffect(() => {
        getNestBoxLiveStatus();
       const interval = setInterval(() => {
           console.log('Running getNestBoxLiveStatus function...');
           getNestBoxLiveStatus();
       }, props.intervalTime);

       return () => clearInterval(interval); // Unmounts the interval for updating the last server time
   }, [props.serverTimestamp]);

   if (networkConnectionState && props.deviceActive) {
       return alertConnected
   } else if (networkConnectionState && !props.deviceActive) {
       return alertDisconnected
   } else {
       return alertDisconnected
   }
}
