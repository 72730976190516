import React from 'react'
import { ListItem, ListItemText, makeStyles } from '@material-ui/core'
import RelocateButtonHandler from './RelocateButtonHandler'

const useStyles = makeStyles({
    root: {
        color: 'grey',
    }
})

export default function NoLocationSet() {
    const classes = useStyles()
    return (
        <React.Fragment>
        <ListItem id='relocate' >
            <ListItemText primary='Unable to load location details for nest box'/>
        </ListItem>
        <RelocateButtonHandler />
        </React.Fragment>
    )
}