export function ReturnFacebookToken(user) {

    const localStorageToken = localStorage.getItem('facebookAccessToken')

    if (localStorageToken) {
        console.log('Returning Facebook Access Token from local storage')
        return localStorageToken
    } else {
        // Query user's Firestore document for the token
        console.log('Querying Firestore for users Facebook Accesss Token')
        // const searchResult = firebase.firestore().collection('users').where('UID', '==', user.uid)
        // const  docId = searchResult
        // console.log('Search result: ')
        // console.log(searchResult)
        return null

    }



}
