import React from 'react'
import { Card, Grid, CardContent, CardMedia, Typography, Avatar, Box, CardHeader, IconButton, makeStyles } from '@material-ui/core'
import {useEffect, useState} from "react";
import firebase from '../../../../firebase'
import { CloudDownload, CloudUpload, Share } from "@material-ui/icons";
import PlayIcon from './PlayIcon'

import { useAuth } from '../../../../context/AuthContext'
import ConfirmImageButtons from './ConfirmImageButtons';
import { data } from 'jquery';
import AppBarTop from '../../../AppBarTop';

import { titleStringSelection, parseTimestamp } from './TimelineFunctions';

import DefaultCard from './DefaultCard';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    marginTop: "10px",

  },
  media: {
    height: 300,
  },
  wrapper: {
    display: 'block',
    overflowY: "auto",
    height: "100%",
    paddingLeft: 10,
    paddingRight: 10
  }
}));
const info = [
  {
    text: "Outside Temperature:",
    data: "temperatureOutside",
  },
  {
    text: "Humidity outside:",
    data: "humidityOutside",
  },
  {
    text: "Weather:",
    data: "weatherDescription",
  },
  {
    text: "Image classification:",
    data: "imageClassification",
  },
  {
    text: "Image classification score:",
    data: "imageClassificationScore",
  },
];

function TimelineScroller() {
  const [loading, setLoading] = useState(true)
  const [cardsInfo, setCardsInfo] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(10);
  const classes = useStyles();
  const { nestBoxId } = useAuth()

  useEffect(() => {
    setLoading(true)
    console.log('Attaching the Firebase listener')
    const unsubscribe = firebase.firestore()
    .collection("nestbox")
    .doc(nestBoxId)
    .collection("triggers")
    .where("imageClassification", "!=", "nesting_material")
    .orderBy("imageClassification", "asc")
    .orderBy("timestampServer", "desc")
    .limit(currentIndex)
    .onSnapshot((querySnapshot) => {
        console.log(querySnapshot)
        const data = [];
        querySnapshot.forEach((doc) => {
            if (doc.exists) {
                data.push({...doc.data(), id: doc.id});
            }
        });
        setCardsInfo(data);
        console.log(data)
      });
    setLoading(false) 
    return unsubscribe;
  }, [currentIndex, data]);

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 1;
    if (bottom) {
      setCurrentIndex(currentIndex + 10);
    }
  };

  if (cardsInfo.length == 0 && !loading) {
    return <DefaultCard />
  }

  return (
    <div className={classes.wrapper} onScroll={handleScroll}>
      <AppBarTop title='Timeline' icon='timeline' />
      {cardsInfo.map((card) => {
        return (
          <Grid container direction='column' alignItems='center' justify='center' >
            <Grid item >
          {/* <Box key={card.id} display="flex" > */}
            <Card className={classes.root} elevation={0}>
              <CardHeader 
              avatar={
                  <Avatar aria-label='nestbox' >
                      NB
                  </Avatar>
              }
              title={titleStringSelection(card.imageClassification)}
              subheader={parseTimestamp(card.timestampServer)}
              />

              <CardMedia className={classes.media} image={card.urlPublic} />
              <CardContent>
                  {info.map((item) => {
                  return (
                      <Typography variant="subtitle2" gutterBottom component="p" color="textSecondary">
                      {item.text} {card[item.data]}
                      </Typography>
                  );
                  })}
              </CardContent>
              <Box>
                  <Box style={{width: 192, float: 'left'}}>
                      <PlayIcon card={card}/>
                      <IconButton disabled={true}>
                          <Share />
                      </IconButton>
                      <IconButton disabled={true}>
                          <CloudUpload />
                      </IconButton>
                      <IconButton disabled={!card.videoPathServer} onClick={(e) => {window.open(card.videoPathServer)}}>
                          <CloudDownload />
                      </IconButton>
                      
                  </Box>
                  <Box style={{width: 96, float: 'right'}}>
                      <ConfirmImageButtons imageClassification={card.imageClassification} docId={card.id} nestBoxId={nestBoxId} userConfirmedClassification={card.userConfirmedClassification}/>
                  </Box>
              </Box>            
            </Card>
          {/* </Box> */}
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
}

export default TimelineScroller;
