import React from 'react'
import { ListItem, ListItemIcon, ListItemText, makeStyles, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        color: 'grey',
    }
})

export default function RelocatingButton() {
    const classes = useStyles()
    return (
        <ListItem id='relocate' >
            <ListItemIcon>
                <CircularProgress size={25} classes={{root: classes.root}}/>
            </ListItemIcon>
            <ListItemText primary='Relocating...' color='grey'/>
        </ListItem>
    )
}
