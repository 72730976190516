import firebase from '../../firebase'

export function CreateFirestoreUser(firstName, lastName, email, uid) {
    const docRef = firebase.firestore().collection('users').doc()

    docRef.set({
        firstName: firstName,
        lastName: lastName,
        email: email,
        UID: uid
    })
}