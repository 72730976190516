import React from 'react'
import { Button, ButtonGroup, Typography} from '@material-ui/core'

export const styles = {
    modalStyles: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: '80%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFF',
        padding: '20px',
        zIndex: 1000
    },
    
    overlayStyles: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, .7)',
        zIndex: 1000
    }
}

export default function HomePageModal({open, handleClose}) {
    if (!open) return null

    return (
        <React.Fragment>
        <div style={styles.overlayStyles} />
        <div style={styles.modalStyles}>
            <Typography variant='h6' gutterBottom>
                Welcome to the new Nest Box Live app
            </Typography>
            <Typography variant='body1' gutterBottom>
                This is the new way to interact with your nest box.
            </Typography>
            <Typography variant='body1' gutterBottom>
                From within the app you can view the camera feed, see recordings, view a 'timeline' of events, live stream to Facebook and much more!
            </Typography>
            <Typography variant='body1' gutterBottom>
                It's the first version release of the app so expect some bugs here and there.
            </Typography>
            <Typography variant='body1' gutterBottom>
                To get the app's full functionality, it's recommended that you sign in with Facebook.
            </Typography>
            <Typography variant='body1'>
                I hope you enjoy it, Jamie :)
            </Typography>
            <br></br>
            <ButtonGroup >
                <Button variant='text' onClick={handleClose}>
                    Close
                </Button>
            </ButtonGroup>
        </div>
        </React.Fragment>
    )
}
