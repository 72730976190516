// React core
import React, { useState, useEffect } from 'react'
import { Navigation} from './navigation/Navigation'
import { AppBar, Box, Toolbar } from '@material-ui/core'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

// Styles
import iPhone11BG from '../images/iPhone11BG.jpg'
import iPadProBG from '../images/iPadProBG.jpg'
import webBG from '../images/WebBG.jpg'
import mobileSmallBG from '../images/mobileSmallBG.jpg'

// Components
import { useMediaQuery } from './functions/useMediaQuery'
import { HomeWindowDisplayer } from './HomeWindowDisplayer'

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
}));


function App() {
  const [backgroundImage, setBackgroundImage] = useState(null)
  const [innerWidth, innerHeight] = useMediaQuery()
  
  const classes = useStyles();
  
  const selectBackgroundImage = (innerWidth, innerHeight) => {
    console.log(innerWidth)
    if (innerWidth <= 360 & innerHeight <= 560) {
        setBackgroundImage(mobileSmallBG)
    } else if (innerWidth <= 414 & innerHeight <= 715) {
        setBackgroundImage(iPhone11BG)
        console.log(backgroundImage)
    } else if (innerWidth <= 768 & innerHeight <= 954) {
        setBackgroundImage(iPadProBG)
    } else {
        setBackgroundImage(webBG)
    }
  }
  useEffect(() =>{
    selectBackgroundImage(innerWidth, innerHeight)
    }, [innerWidth, innerHeight])

  return (
    <React.Fragment>
    <Router>
      <div id='backgroundImage' style={{ position: 'fixed', backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: '100vw', height: '100%', paddingBottom: 56}} >
          <HomeWindowDisplayer innerWidth={innerWidth} innerHeight={innerHeight}/>
      <AppBar position='fixed' className={classes.appBar}>
        <Navigation/>        
      </AppBar>
      </div>
    </Router>
    </React.Fragment>
  )
}

export default App;

