import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: '100%',
      margin: 10,
      backgroundColor: theme.palette.background.paper,
    },
  }));

export default useStyles