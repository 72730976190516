import $ from 'jquery'
import md5 from 'crypto-js/md5'
import firebase from '../../firebase'

const serverUrl = 'https://uk-01.nestboxlive.app/mistinterface/api2?command='
const myHeaders = new Headers();
myHeaders.append("Content-Type", "json/application");

export async function requestFacebookStreamKey(facebookAccessToken, facebookUserId) {
    const title = escape('Live Streaming from Nest Box Live')
    const description = escape('Live from my nest box')
    const url = `https://graph.facebook.com/${facebookUserId}/live_videos?status=LIVE_NOW&title=${title}&description=${description}&access_token=${facebookAccessToken}`
    // const url2 = 'https://graph.facebook.com/3541075909354622/live_videos?status=LIVE_NOW&title=Test1&description=Test1&access_token=EAACQv0TscXkBANP2qJEw9BREdSfc3foWYPuTAaKztzKSQL4RrGvqzjmGU6Pb4MlnbxNg5ifmZAljtPBOWeJ6SwylSKMuobVFiG8fS2ZAUYjh7Dv2CnvOISZA5FoApBSdMxSkKaNCMe8YVoWdF3zdNP9qf4ChLWwAmD1qoHfhKNvnqkI3QDG48cMlZC1ZBIdkZD'
    const response = await $.post(url)
    console.log(response)
    return response
}

export async function publishLiveVideo (streamKey, nestBoxId) {
    const target = escape(`${streamKey}?`)
    const streamName = `pinestbox%2b${nestBoxId}`
    const now = Math.floor(Date.now() / 1000)
    const completeTime = Math.floor(now + 28800) // 8 hours from now (Facebook max stream time is limited)
    console.log(completeTime)

    const push_auto_add = [streamName, target, now, completeTime]

    const challengeCode = await getMistChallenge()
    const passwordResponse = await getMd5Response(challengeCode)

    const data = {
        'authorize': {
            'username': 'nestboxlive',
            'password': passwordResponse
        },
        push_auto_add
    }

    const url = serverUrl + JSON.stringify(data)
    console.log(url)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    }

    const response = await fetch(url, requestOptions)
    const responseJson = response.json()
    console.log('Mist API response to publish new video')
    console.log(responseJson)

    // Update Firestore client settings document to set userLiveStreaming to True and
    // set timeout to timeout time

    const docRef = firebase.firestore().collection('nestbox').doc(nestBoxId).collection('client').doc('settings')
    
    const firestoreUpdateData = {
        userLiveStreaming: true,
        userLiveStreamingTimeout: completeTime
    }
    await docRef.update(firestoreUpdateData)

    // We also need to update Firestore with the Mist push_auto_add data array so we can remove the push
    // from Mist later

    const mistVideoRef = firebase.firestore().collection('nestbox').doc(nestBoxId).collection('client').doc('settings').collection('liveStreams').doc('currentFbLiveStream')
    const mistData = {
        pushAutoAdd: push_auto_add,
        streamKey: streamKey
    }
    await mistVideoRef.set(mistData)

    return push_auto_add
}

export async function getUserLiveVideos (facebookUserId, facebookAccessToken) {

    const url = `https://graph.facebook.com/${facebookUserId}/live_videos?&access_token=${facebookAccessToken}`

    const request = {
        'url': url,
        'method': 'GET',
        'timeout': 0,
        'dataType': 'jsonp',
    }

    let usersLiveVideos = []

    console.log('Current Users Live Videos:')
    try {
        const response = await $.get(request)
        console.log(response)
        for (let i = 0; i < response.data.length; i++) {
            let item = response.data[i]
            if (item.status === 'LIVE') {
                console.log(item)
                usersLiveVideos.push(item.id) // Pushes the current users Facebook live video IDs into the array
            }
        }
        usersLiveVideos.length > 1 && console.log('User has more than one live video running')
        console.log(usersLiveVideos)
        return usersLiveVideos
    } catch (error) {
        console.log('Error returning live videos from Facebook')
        console.log(error)
    }

}

export async function stopLiveVideo (liveVideoId, facebookAccessToken, nestBoxId, push_auto_add1) {
    console.log('Stopping the live video')
    console.log('Live video id: ' + liveVideoId[0])
    console.log(facebookAccessToken)
    const liveVideoId0 = liveVideoId[0]
    console.log(liveVideoId0)

    // Stop the live video on Facebook
    const url = `https://graph.facebook.com/${liveVideoId0}?access_token=${facebookAccessToken}`
    
    const request = {
        'url': url,
        'method': 'DELETE',
    }
    const response = await $.ajax(request)
    console.log(response)
    console.log('Live video stopped: ' + response.success)

    // Get the Mist push_auto_add data from Firestore
    const mistData = await firebase.firestore().collection('nestbox').doc(nestBoxId).collection('client').doc('settings').collection('liveStreams').doc('currentFbLiveStream').get()
    const mistDataDoc = mistData.data()

    console.log('Mist audo add data from Firestore:')
    console.log(mistDataDoc.pushAutoAdd[0])

    // Stop the live video on Mist Server
    await stopMistPush(mistDataDoc.pushAutoAdd[0])

    // Delete the live video details from Firestore
    firebase.firestore().collection('nestbox').doc(nestBoxId).collection('client').doc('settings').collection('liveStreams').doc('currentFbLiveStream').delete()

    // Update the Firestore client > settings document
    const docRef = firebase.firestore().collection('nestbox').doc(nestBoxId).collection('client').doc('settings')
    
    const firestoreUpdateData = {
        userLiveStreaming: false,
        userLiveStreamingTimeout: null
    }
    try {
        await docRef.update(firestoreUpdateData)
    } catch (e) {
        console.log(e)
    }

    return response.success

}

async function stopMistPush (push_auto_add) {
    console.log('Removing the push from Mist Server')
    const challengeCode = await getMistChallenge()
    const passwordResponse = await getMd5Response(challengeCode)

    const serverUrl = 'https://uk-01.nestboxlive.app/mistinterface/api2?command='

    const data = {
        'authorize': {
            'username': 'nestboxlive',
            'password': passwordResponse
        },
        "push_auto_remove": push_auto_add
    }

    const url = serverUrl + JSON.stringify(data)
    console.log(url)

    const requestOptions = {
        method: 'POST',
        mode: 'no-cors',
        headers: myHeaders,
        redirect: 'follow'
    }

    const resposne = await fetch(url, requestOptions)
    // const jsonResponse = resposne.json()
    console.log('Stop Mist push response:')
    console.log(resposne)
}

export async function getUserLastPost(facebookUserId, facebookAccessToken) {
    console.log('Getting the users last post...')

    const url = `https://graph.facebook.com/${facebookUserId}/posts?access_token=${facebookAccessToken}`

    const request = {
        'url': url,
        'method': 'GET',
    }
    const response = await $.ajax(request)
    console.log(response)
    const lastPost = response.data[0].id
    console.log(response.data[0].id)

    const postUrl = `https://www.facebook.com/${lastPost}`
    console.log('Last post url:')
    console.log(postUrl)
    return postUrl
    
}

export async function checkStreamStatus (nestBoxId) {
    console.log('Checking to see if the stream is active...')
    const mistResponse = await getMistStatsStream()
    console.log(mistResponse)
    if (mistResponse.stats_streams) {
        console.log('Mist response:')
        console.log(mistResponse.stats_streams)
        const streamResponse = mistResponse.stats_streams.some((item) => item === `pinestbox+${nestBoxId}`)
        if (streamResponse === true) {
            console.log('Stream is active in Mist!')
            return streamResponse
        }
    } else {
        console.log('No response from Mist')
        return false
    }
}

async function getMistStatsStream () {
    console.log('Getting Mist Stats Stream...')

    const challengeCode = await getMistChallenge()
    const passwordResponse = await getMd5Response(challengeCode)

    const serverUrl = 'https://uk-01.nestboxlive.app/mistinterface/api2?command='

    const data = {
        'authorize': {
            'username': 'nestboxlive',
            'password': passwordResponse
        },
        'stats_streams': true
    }

    const url = serverUrl + JSON.stringify(data)
    console.log(url)

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    }

    const response = await fetch(url, requestOptions)
    const responseJson = response.json()
    return responseJson

}

async function getMistChallenge() {
    console.log('Getting Mist challenge code...')

    const serverUrl = 'https://uk-01.nestboxlive.app/mistinterface/api2?command='

    const request = {
        'url': serverUrl,
        'method': 'GET',
        'timeout': 0,
        'dataType': 'jsonp',
    }

    const response = await $.ajax(request)
    console.log(`Challenge code: ${response.authorize.challenge}`)

    return response.authorize.challenge

}

function getMd5Response(challengeCode) {
    const hashedPassword = md5(')~nS<#yGN[{dt5#92e)bKHHN6qn*D#4*')

    const hashedCredentials = md5(hashedPassword + challengeCode)

    return hashedCredentials.toString()
}