import React, { useState } from 'react'
import { ButtonBase, CardMedia, makeStyles } from '@material-ui/core'
import VideoModal from './VideoModal'

const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: "left",
      marginTop: "10px",
    },
    media: {
      width: 380,
      height: 290,
    },
    wrapper: {
      overflowY: "auto",
      // overflowX: "auto"
      height: "100vh",
    }
  }));

export default function VideoCardModalHandler(props) {
    const [loading, setLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const classes = useStyles();

    const handleCloseModal = () => {
        setLoading(true)
        setModalOpen(false)
        setLoading(false)
    }

    return (
        <React.Fragment>
        <ButtonBase onClick={(e) => {setModalOpen(true)}} >                        
        <CardMedia className={classes.media} image={props.urlPublic} />
        </ButtonBase>
        <VideoModal open={modalOpen} handleClose={handleCloseModal} videoPathServer={props.videoPathServer}>
            {props.videoPathServer}
        </VideoModal>
        </React.Fragment>
    )
}
