import React from 'react'
import { BrowserRouter as Router, Route, Switch, MemoryRouter } from 'react-router-dom'
import { AuthProvider } from '../context/AuthContext'
import HomeContainer from './HomeContainer'
import Home from './Home'
import SignUpHome from './signUp/SignUpHome'
import PrivateRoute from './PrivateRoute'
import PrivateRouteHome from './PrivateRouteHome'
import SetupInstructionsMain from './setupInstructions/SetupInstructionsMain'

function App() {
  
  console.log('Build:')
  console.log(process.env.NODE_ENV)
  if (process.env.NODE_ENV === 'production')
  console.log = function no_console() {};

  return (
      <Router>
        <AuthProvider>
          <Switch>
            <MemoryRouter>
              
            {/* <PrivateRoute exact path='/' component={HomeContainer} /> */}
            {/* <PrivateRouteHome path='/home' component={Home} /> */}
            <Route path='/' component={SignUpHome} /> 
            {/* <Route path ='/setup' component={SetupInstructionsMain} /> */}

            </MemoryRouter>
          </Switch>
      </AuthProvider>
      </Router>
  );
}

export default App;

