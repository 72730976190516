import React, { useContext, useState, useEffect, useRef } from 'react'
import firebase from '../firebase'
import { CreateFirestoreUser } from '../containers/signUp/CreateFirestoreUser'
import { ReturnFacebookToken } from '../containers/functions/ReturnFacebookToken'
import { deviceType, isMobileSafari, isIOS, isMobile, isTablet, isDesktop, isChrome, isSafari } from 'react-device-detect'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [nestBoxId, setNestBoxId] = useState(null)
    const [facebookAccessToken, setFacebookAccessToken] = useState(null)
    const [facebookUserId, setFacebookUserId] = useState(null)
    const [facebookLiveNow, setFacebookLiveNow] = useState(false)
    const [deviceInfo, setDeviceInfo] = useState(null)
    const [provider, setProvider] = useState(null)

    const auth = firebase.auth()

    function signup(firstName, lastName, email, password) {
        const signUpPromise = auth.createUserWithEmailAndPassword(email, password).then((userCredential) => {
            const uid = userCredential.user.uid
            !!uid && CreateFirestoreUser(firstName, lastName, email, uid)

        })
        // Create the user in Cloud Firestore
        // console.log(signUpPromise.user.uid)
        return signUpPromise
    }

    function logout() {
        return auth.signOut()
    }

    function setNestBoxContext(id) {
        setNestBoxId(id)
    }

    function handleFacebookUserId(id) {
        if (id) {
            setFacebookUserId(id)
        }

    }

    function handleProviderId(user) {
        if (user) {
            setProvider(user.providerData[0].providerId)
        }
    }

    function deviceDetect() {
        console.log('Device info:')
        const device = {
            'deviceType': deviceType,
            'isIOS': isIOS,
            'isMobile': isMobile,
            'isTablet': isTablet,
            'isDesktop': isDesktop,
            'isChrome': isChrome,
            'isSafari': isSafari,
            'isMobileSafari': isMobileSafari
        }
        setDeviceInfo(device)
        console.log(device)

    }

    function setFacebookLiveStatus(status) {
        setFacebookLiveNow(status)
    }

    function setUserActiveState(state) {
        const doc_ref = firebase.firestore().collection('nestbox').doc('10001').collection('client').doc('settings')
        doc_ref.update({userActive: {state}})
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            const tokenResponse = ReturnFacebookToken(user)
            setFacebookAccessToken(tokenResponse)
            handleProviderId(user)
            deviceDetect()
            setLoading(false)
        })
        // Search Firestore for nest box ID for the current user

        return () => {
            unsubscribe()
        }
    }, [])


    const value = {
        currentUser,
        signup,
        logout,
        setNestBoxContext,
        nestBoxId,
        facebookAccessToken,
        handleFacebookUserId,
        facebookUserId,
        deviceInfo,
        provider,
        setFacebookLiveStatus,
        facebookLiveNow

    }
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
