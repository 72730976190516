import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab';

const alertOnline = (
    <Alert severity='success' style={{width: '100%'}}>
        <AlertTitle>Nest Box is Online</AlertTitle>
        Device is Online and not reporting any issues
    </Alert>
)

const alertOnlineIssues = (
    <Alert severity='warning' style={{width: '100%'}}>
    <AlertTitle>Nest Box is Online</AlertTitle>
    Device is Online but reporting issues
</Alert>
)

const alertOffline = (
    <Alert severity='error' style={{width: '100%'}}>
        <AlertTitle>Nest Box is Offline</AlertTitle>
        Please check the wired connection
    </Alert>
)

export default function TopStatusBar(props) {
    
    if (props.deviceActive && props.serviceSoftware) {
        return alertOnline
    } else if (props.deviceActive && !props.serviceSoftware) {
        return alertOnlineIssues
    } else {
        return alertOffline
    }
}
