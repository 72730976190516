import React, { useState } from 'react'
// import FacebookIcon from '../../images/FacebookIconSmall.png'
import { ButtonGroup, Button, IconButton, SvgIcon, Icon, makeStyles } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook';
import SvgFacebookLive from '../../icons/FBLive_Hex-RGB-1013.svg'
import LiveButtonModal from './LiveButtonModal';
import LiveStreamingAnimation from './LiveStreamingAnimation';

const useStyles = makeStyles({
    imageIcon: { height: 60, width: 200 },
    iconRoot: { textAlign: 'center' },
  });

export function FacebookLiveButton(props) {
    const [openModal, setOpenModal] = useState(false)
    const classes = useStyles()

    const handleToggleModal = (state) => {
        setOpenModal(!openModal)
    }

    return (
        <React.Fragment>
        <Button >
            <IconButton onClick={(e) => {handleToggleModal(true)}} disabled={props.loading}>
                <img className={classes.imageIcon} src={SvgFacebookLive} />
            </IconButton>
        </Button>
        <LiveButtonModal open={openModal} onClose={(e) => {handleToggleModal(false)}} handleLiveButtonClick={props.handleLiveButtonClick} />
        </React.Fragment>
    )
}
