import React, { useState, useEffect } from 'react'
import { Alert } from '@material-ui/lab'
import { LinearProgress } from '@material-ui/core'
import { AlertTitle } from '@material-ui/lab'

const online = (
    <Alert severity='success'>
        <AlertTitle>Online</AlertTitle>
        Nest box is online and reporting no issues!
    </Alert>
)
const issues = (
    <Alert severity='warning'>
        <AlertTitle>Issues</AlertTitle>
        Nest box is online, but reporting issues!
    </Alert>
)
const offline = (
    <Alert severity='error'>
        <AlertTitle>Offline</AlertTitle>
        Nest box is currently offline!
    </Alert>
)

export function TopBar(props) {
    const [status, setStatus] = useState(null);
    const [servicesStatus, setServicesStatus] = useState(null)

    const serviceSoftware = props.serviceSoftware

    function getStatus() {
        // setLoading(true);
        let now = new Date().getTime() / 1000;
        const lastUpdate = Math.round(now - props.serverTimestamp);
        if (lastUpdate < props.timeoutDelta) {
            setStatus(true);
            // console.log('Setting status to true');
        } else {
            setStatus(false);
            // console.log('Setting status to false');
        };
        // setLoading(false);
    };

    function getServicesStatus() {
        if (props.serviceSoftware === false || props.serviceData === false || props.serviceLed === false) {
            setServicesStatus(false)
        } else {
            setServicesStatus(true)
        }
    }
    
    useEffect(() => {
        getStatus();
        getServicesStatus();
       const interval = setInterval(() => {
        //    console.log('Running getNestBoxLiveStatus function...');
        getStatus();
        getServicesStatus();
       }, props.intervalTime);

       return () => clearInterval(interval); // Unmounts the interval for updating the last server time
   }, [props.serverTimestamp, props.serviceSoftware]);
    

    if (status === null) {
        return <LinearProgress />
    } else if (status === true & servicesStatus === true) {
        return online
    } else if (status === false) {
        return offline
    } else {
        return issues
    }
}
